.additional {
	position: relative;
	padding: 0;
	margin-top: 1rem;
	@include min(600px){
		&::after{
			position: absolute;
			content: "";
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			max-width: 1920px;
			width: 100%;
			padding-bottom: 116px;
			background: transparent url(/images/local/v-202503271106/home-bottom-prechod.png) no-repeat center bottom;
			z-index: 25;
		}
	}
	.centered{
		padding-top: 3rem;
		padding-bottom: 2rem;
		background: $color-secondary;
		color: $color-light;
	}
	@include min(750px){
		margin-top: 15%;
		.centered{
			padding-top: 4rem;
			padding-bottom: 6.5rem;
			background: linear-gradient(90deg, #0061cf 40%, #003fcf 60%);
		}
	}

	@include link {
		color: $color-light;
		text-decoration: underline;
	}

	@include link-over {
		text-decoration: none;
	}

	&__content {
		//@include grid(600px 2 3%, 1024px 3 3%);
		display: grid;
		grid-gap: 2rem;
		gap: 2rem;
		@include min(750px){
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 3%;
			gap: 3%;
		}
		@include min(1024px){
			grid-template-columns: .75fr .75fr 1.5fr;
		}
	}
	.element-type-heading {
		font-size: rem(27px);
		font-family: $font-body;
		color: $color-light;
		font-weight: 800;
		margin-bottom: .75em;
	}
	.text-component{
		margin-bottom: 0;
	}
}

.office-hours{
	color: $color-light;
	.table-wrapper{
		margin: 0;
	}
	table{
		margin: 0;
	}
	tbody{
		tr{
			border-bottom: 0;
			&:nth-child(odd){
				background-color: transparent;
			}
		}
		td{
			padding: 0;
			min-width: 0;
		}
	}
}
