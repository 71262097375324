//Tělo dokumentu
.article {
	&__heading {
		font-size: rem(34px);
		line-height: 1.15;
		margin: 0 0 .75em;
		font-weight: 800;
		.-nosidebar &{
			text-align: center;
		}
	}

	&__approved {
		padding: 1em 0 0;
	}

	&__effectiveness {

	}

	&__footer {
		margin: 1.5em 0 2em;

		div {
			display: inline-block;

			input[type="date"],
			input[type="time"] {
				display: inline;
				border: 0;
				padding: 0 .5em;
				border-radius: 3px;
			}
		}

		.invalid ul {
			margin: 0;
			padding: 0 0 0 .5em;
		}
	}

	&__perex {
		font-size: 1.1em;
		margin-bottom: 1.5em;
	}

	&__image {
		margin-left: auto;
		margin-right: auto;
	}

	&__body {
		margin-bottom: 0em;
	}

	&__author {
		text-align: left;
		clear: both;
		margin: 1.5em 0;
		display: inline;
		font-style: normal;

	}

	&__datespan {
		margin-bottom: 3em;
	}

	&__date {
		font-weight: bold;
	}
}



.article-link {
	&__image {
		display: block;
		max-width: 240px;
		margin: 0 0 1rem;
		flex-shrink: 0;

		.article__link-elements & {
			max-width: 100px;
		}

		@include min(500px) {
			display: inline-block;
			float: left;
			margin: 0 1rem 0 0;
		}
	}

	&__title {
		font-size: rem(18px);
	}

	&__description {
		font-size: rem(16px);
		margin-top: 0;
	}
}

@include link(".article-link", false) {
	color: $default-document-link-color;
	text-decoration: underline;
}

@include link-visited(".article-link") {
	color: darken($default-document-link-color, 10%);
}

@include link-over(".article-link") {
	text-decoration: none;
}
