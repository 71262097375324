
.contact-form {
	padding: 0;
	margin-bottom: 0;

	.form__section {
		margin-bottom: .5em;
	}

	&__input,
	&__textarea {
		background-color: $color-light;
		border-color: $color-dark;
		color: $color-dark;
		border-radius: 1px;
	}

	&__input {
		min-height: 55px;
	}

	&__info:first-child {
		margin: .55em 0 .9em;
	}

	&__button {

	}
}
