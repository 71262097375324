.main-nav {
	color: $color-dark;
	padding: 0 0 0;
	.nav{
		&.-root-nav {
			@include flex(df, fww, aic, jcc, fdc);
			margin: 1rem 0 0;
			padding: 0;
			@include min(600px){
				margin: 0;
				flex-direction: row;
			}
		}
		&__item {
			@include flex(dif, aic, jcc);
			margin: 0 1.5rem 0;
			font-family: $font-headings;
			font-size: rem(16px);
			font-weight: 700;
			text-align: center;
		}

		&__link {
			position: relative;
			display: block;
			padding: .75em 0;
			line-height: 1;
			text-transform: uppercase;

			@include link(&) {
				color: $color-dark;
				text-decoration: none;
			}
			@include link-over(&){
				color: $color-secondary;
			}

			&.-active,
			&.-active-parent {
				text-decoration: none;

				@include link(&) {
					color: $color-secondary;
				}
			}
		}
		&__description {
			display: none;
		}
	}
	@include max($nav-horizontal-burger-threshold - 1px) {
		transition: max-height 0.3s ease-out;
		max-height: 0;
		overflow: hidden;
		padding: 0;
		&__item{
			animation: menuFadeOut 0.1s ease-out 0s forwards;
			opacity: 0;

			@for $li from 2 through 10 {
				$delay: (50 * ($li - 1));
				&:nth-of-type(#{$li}) {
					animation-delay: to-length($delay, "ms") !important;
				}
			}
		}

		.header__nav--toggled &{
			max-height: 20rem;

			&__item{
				animation: menuFadeIn 0.3s ease-out 0s forwards;
			}
		}
	}
}
