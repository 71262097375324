#google_translate_element {
	display: inline-block;
	margin: 0;
	align-self: center;
	flex-shrink: 0;

	&.field {
		background-color: transparent;
	}

	.goog-te-gadget-simple {
		border: 0;
		width: 100%;
		padding-bottom: 1px;
		background-color: transparent;
		display: flex;
		align-items: center;

		&,
		& * {
			font-family: $font-headings;
			font-size: rem(16px) !important;
		}

		.goog-te-gadget-icon {
			width: 24px;
			height: 24px;
			padding: 0;
			order: 2;
			margin: 0;
			background-repeat: no-repeat;
		}

		[aria-haspopup] {
			margin-right: 0;
			display: none;
			text-decoration: none !important;

			span {
				&:nth-of-type(1) {
					padding: 0 3px;
					display: inline-block;
					color: $color-dark;
					text-decoration: underline;

					@include link-over(&) {
						text-decoration: none;
					}
				}

				&:nth-of-type(2) {
					padding-right: 3px;
					border-left: 0 !important;
				}

				&:nth-of-type(3) {
					display: none;
					background: none;
					color: $color-dark !important;
				}
			}

			img {
				display: none;
			}
		}
	}
}

@include max(599px) {

	#google_translate_element,
	#goog-gt-tt,
	#goog-gt-tt+div>object,
	iframe.goog-te-menu-frame,
	iframe.goog-te-banner-frame,
	.header__social .list__item.-gte {
		display: none !important;
	}
}
