.header {
	position: relative;
	z-index: 35;
	background-color: $color-light;
	color: $color-dark;
	padding: 1rem 0 2rem;
	@include min(600px) {
		padding: 1rem;
		margin: 0;
	}
	@include link(){
		color: $color-dark;
	}

	&__title {
		position: relative;
		margin: 0 auto;

		@include min(500px) {
			margin: 0;
		}
	}

	&__home-link {
		@include fill;
	}

	&__content {
		z-index: 2;
		max-width: 1760px;
		@include flex(df, aic, jcc, fdc);
	}
	&__heading-container {
		grid-area: header__heading-container;
		position: relative;
		@include flex(df, aic, jcc);
		align-self: flex-start;
		padding-left: 30px;
	}
	&__logo {
		display: block;
		margin: 0 1.3rem 0 0;
	}

	&__heading {
		text-align: left;
		padding: 0;
		margin: 0;
		color: $color-secondary;
		line-height: 1;
		font-size: rem(40px);
		font-weight: 800;
	}
	&__heading-status {
		font-size: rem(20px);
		color: $color-base;
		font-weight: 400;
		display: block;
	}
	&__subheading {
		left: 10px;
		position: absolute;
		transform: rotate(-90deg);
		margin: 0;
		transform-origin: 0% 50%;
		display: block;
		font-size: rem(12px);
		font-weight: 400;
		color: $color-base;
	}
	&__home-link {
		color: inherit;
		text-decoration-color: none;
	}

	&__additional {
		grid-area: header__additional;
		gap: 1rem;
		width: 100%;
		height: 0;
		overflow: hidden;
		opacity: 0;
		transition: opacity .5s ease-in;
		.header__nav--toggled + &{
			margin-top: 1rem;
			height: auto;
			@include flex(df, aic, jcc);
			opacity: 1;
		}
		@include min(600px){
			@include flex(df, aic, jcc);
			opacity: 1;
			width: auto;
			height: auto;
			margin-top: 0;
			margin-bottom: 1rem;
		}
		@include min(900px){
			margin-bottom: 0;
		}
	}
	&__social{
		background-color: $color-secondary;
		box-shadow: -10px 0 25px rgba($color-dark, .75);
		width: 100%;
		.list{
			@include flex(df, fww, aic, jcc);
			gap: 1.25rem;
			margin-bottom: 0;
			padding: 1rem 1.85rem;
			width: 100%;
			&__item{
				display: inline-flex;
				margin-bottom: 0;
			}
			&__link{
				display: inline-flex;
				flex-direction: column;
				align-items: center;
				font-size: rem(12px);
				font-weight: 700;
				gap: 0.25rem;
				max-width: 5em;
				text-align: center;
				line-height: 1.24;
			}
			@include min(900px){
				padding: 2rem 1rem;
				flex-direction: column;
			}
		}
		object {
			pointer-events: none;
		}

		@include link() {
			color: $color-light;
			text-decoration: none;
			svg {
				fill: $color-light;
			}
		}
		@include link-over() {
			text-decoration: underline;
		}
		@include min(600px){
			width: auto;
			.list{
				width: auto;
			}
		}
	}
	&__nav{
		grid-area: main-nav;
		@include flex(df, aic, jcc);
		flex-shrink: 0;
		@include max(599px){
			order: 4;
		}
	}
	&__nav-button {
		position: absolute;
		right: 1rem;
		top: .5rem;
		display: inline-flex;
		align-items: flex-end;
		justify-content: center;
		flex-direction: column;
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		@include min(600px) {
			display: none;
		}

		@include link(&) {
			text-decoration: none;
		}

		> .hamburger {
			padding: 8px 7px;
			//border: 1px solid currentColor;
			border-radius: 5px;
		}

		.nav-button__title {
			font-size: rem(15px);
			margin: 0;
		}
	}
}
@include min(600px) {
	.header{
		&__content{
			display: grid;
			grid-template-columns: max-content 1fr max-content;
			justify-content: space-between;
			grid-template-areas:
				"header__heading-container . header__additional"
				"main-nav main-nav main-nav";
		}
	}
}

@include min(900px) {
	.header{
		&__content{
			padding-right: 110px;
			display: grid;
			grid-template-columns: max-content 1fr max-content;
			justify-content: space-between;
			grid-template-areas:
				"header__heading-container . header__additional"
				"main-nav main-nav main-nav";
		}
		&__social{
			position: absolute;
			right: 0;
			top: -1rem;
		}
	}
}

@include min(1200px) {
	.header__content{
		grid-template-areas: "header__heading-container main-nav header__additional";
	}
}
