@charset "UTF-8";
/*!
*  Author: WEBHOUSE, s. r. o.
*  Version: 1.0
*/
/*
* BASE DEPENDENCIES
*/
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700;800;900&family=Signika+Negative:wght@300;700&display=swap");
/*
* SHARED
*/
/*****************************************
* blackwell - FUNCTIONS
*****************************************/
/*************************
* MIXINY PRO ODRÁŽKY
*************************/
/*****************************************
* Blackwell - MIXINS
*****************************************/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/*==============================
	DEFAULTS
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	TABS
==============================*/
/*==============================
	BUTTONS
==============================*/
/*==============================
	CALENDAR -PAGE
==============================*/
@keyframes fade-into-foreground {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1.2);
    opacity: 0;
  }
}

@keyframes fade-into-background {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.75);
    opacity: 0;
  }
}

@keyframes pop-into-foreground {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pop-into-foreground-with-bounce {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  72% {
    transform: scale(1.07);
    opacity: .72;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
  }
  100% {
    box-shadow: 0 0 0 1.5em rgba(0, 0, 0, 0);
  }
}

body {
  font-family: "Nunito", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Nunito", sans-serif;
}

h1, h2, h3, h4 {
  font-weight: 800;
  line-height: 1.15;
  margin: 0 0 .3em 0;
  /* * + & {
		margin-top: 2em;
	} */
}

h5, h6 {
  font-size: 700;
  margin: 0 0 .5em 0;
}

* + h5, * + h6 {
  margin-top: 1.65em;
}

h2,
h3.-size-h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.375rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1.125rem;
}

a,
a:link {
  color: #162847;
}

a:visited {
  color: #223e6e;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
}

._fsr {
  position: absolute !important;
  left: -9999rem !important;
  top: auto;
  outline: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}

._nls {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
}

/*
* COMMON BLOCKS
*/
html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  margin: 0 auto;
  font-family: "Nunito", sans-serif;
}

.centered {
  max-width: 1490px;
  width: 94%;
  margin-left: auto;
  margin-right: auto;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  padding-right: 0;
  margin: 0 -2.25vw 3em;
}

.grid__item {
  flex-grow: 0;
  padding: 2.25vw;
}

.grid__item.-fl {
  display: flex;
  flex-direction: column;
}

.grid__item.-fl > :only-child {
  flex-grow: 1;
}

.grid.-no-spacing {
  margin-left: 0;
  margin-right: 0;
}

.grid.-no-spacing .grid__item {
  padding: 0;
}

.grid.-halves .grid__item {
  display: grid;
}

@media screen and (min-width: 649px) {
  .grid.-halves .grid__item {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0;
    gap: 0;
  }
}

.grid.-thirds .grid__item {
  display: grid;
}

@media screen and (min-width: 600px) and (max-width: 800px) {
  .grid.-thirds .grid__item {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0;
    gap: 0;
  }
}

@media screen and (min-width: 799px) {
  .grid.-thirds .grid__item {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0;
    gap: 0;
  }
}

@media screen and (min-width: 800px) {
  .grid.-thirds:not(.-no-spacing) {
    margin-left: -1.6875vw;
    margin-right: -1.6875vw;
  }
  .grid.-thirds:not(.-no-spacing) .grid__item {
    padding: 1.6875vw;
  }
}

.grid.-quarters .grid__item {
  display: grid;
}

@media screen and (min-width: 600px) and (max-width: 800px) {
  .grid.-quarters .grid__item {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0;
    gap: 0;
  }
}

@media screen and (min-width: 800px) and (max-width: 1024px) {
  .grid.-quarters .grid__item {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0;
    gap: 0;
  }
}

@media screen and (min-width: 1023px) {
  .grid.-quarters .grid__item {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0;
    gap: 0;
  }
}

@media screen and (min-width: 800px) {
  .grid.-quarters:not(.-no-spacing) {
    margin-left: -1.6875vw;
    margin-right: -1.6875vw;
  }
  .grid.-quarters:not(.-no-spacing) .grid__item {
    padding: 1.6875vw;
  }
}

@media screen and (min-width: 1024px) {
  .grid.-quarters:not(.-no-spacing) {
    margin-left: -1.125vw;
    margin-right: -1.125vw;
  }
  .grid.-quarters:not(.-no-spacing) .grid__item {
    padding: 1.125vw;
  }
}

.agnostic-grid {
  margin: 0 0 3em;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
}

a.keyboard-focus:focus,
video.keyboard-focus:focus,
button.keyboard-focus:focus,
summary.keyboard-focus:focus {
  outline: 3px lightskyblue solid;
  outline-offset: 2px;
  -moz-outline-radius: 3px;
}

/*==============================
	INPUT, TEXTAREA, SELECT
==============================*/
.input, .textarea {
  transition: border-color .11s ease, box-shadow .21s ease;
  box-shadow: 0;
  width: 100%;
  font-size: 1em;
  border-radius: 4px;
  border-color: #cccccc;
  border-width: 1px;
  background-color: #ffffff;
  color: #2a2a2a;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input:focus, .textarea:focus {
  border-color: #6b9aeb;
  box-shadow: 0 8px 26px rgba(0, 0, 0, 0.12);
  outline: 0;
}

.input[disabled], .textarea[disabled] {
  border-color: #e3e3e3;
  background-color: #ebebeb;
  color: #444444;
  cursor: not-allowed;
}

/*==============================
	CHECKBOX, SWITCH, RADIO
==============================*/
[class*="lazy-"] {
  transition: filter .5s ease-out;
  filter: blur(20px);
}

[class*="lazy-"]:not(.loaded) {
  display: block;
  background: #dedede;
  width: 100%;
  min-width: 240px;
  padding-bottom: 56.25%;
}

[class*="lazy-"].loaded {
  filter: blur(0);
}

body {
  color: #353b42;
}

::selection {
  background-color: #1a86d0;
  color: #ffffff;
}

.centered {
  max-width: 1490px;
  width: 94%;
  margin-left: auto;
  margin-right: auto;
}

.centered.-narrow {
  max-width: 920px;
}

.centered.-padded {
  width: 100%;
  padding-left: 4%;
  padding-right: 4%;
}

.centered.-padded-left {
  padding-left: 4%;
}

.centered.-padded-right {
  padding-right: 4%;
}

.main-wrapper {
  background: #e9f1f9;
}

.main-wrapper > .centered {
  display: grid;
  grid-gap: 0 70px;
  gap: 0 70px;
}

@media screen and (min-width: 750px) {
  .main-wrapper {
    margin-top: -50px;
    padding-top: 50px;
    background: #e9f1f9 url(/images/local/v-202503271106/page-bottom-bg.jpg) no-repeat center bottom;
  }
}

@media screen and (min-width: 800px) {
  .main-wrapper.-sidebar > .centered {
    grid-template-areas: "sidebar main";
    grid-template-columns: 444px 1fr;
  }
  .main-wrapper.-sidebar > .centered .main {
    grid-area: main;
  }
  .main-wrapper.-sidebar > .centered .sidebar {
    position: relative;
    z-index: 20;
    grid-area: sidebar;
    margin-top: -5.6rem;
  }
  .main-wrapper .content {
    min-height: 750px;
    padding-bottom: 40%;
  }
}

.table-wrapper {
  max-width: 100%;
  overflow-x: auto;
  margin-bottom: 2em;
}

.table-wrapper table {
  margin-bottom: 1em;
}

.sidebar {
  padding-bottom: 2em;
}

.content {
  margin-left: auto;
  margin-right: auto;
}

.more {
  text-align: center;
}

.more__button {
  background: #fbd834;
  padding: 1em 2em;
  font-weight: 700;
  display: inline-block;
  font-size: 1rem;
  min-width: 190px;
  border-radius: 5px;
  transition: background .3s ease-in-out, color .3s ease-in-out, box-shadow .3s ease-in-out;
}

.more__button,
.more__button:link,
.more__button:visited {
  color: #162847;
  text-decoration: none;
}

.more__button:hover,
.more__button:focus,
.more__button:active {
  background: linear-gradient(90deg, #0061cf 0%, #003fcf 100%);
  color: #ffffff;
  box-shadow: 0px 35px 25px -25px rgba(0, 0, 0, 0.25);
}

.header {
  position: relative;
  z-index: 35;
  background-color: #ffffff;
  color: #292A2D;
  padding: 1rem 0 2rem;
}

@media screen and (min-width: 600px) {
  .header {
    padding: 1rem;
    margin: 0;
  }
}

.header a,
.header a:link,
.header a:visited {
  color: #292A2D;
}

.header__title {
  position: relative;
  margin: 0 auto;
}

@media screen and (min-width: 500px) {
  .header__title {
    margin: 0;
  }
}

.header__home-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.header__content {
  z-index: 2;
  max-width: 1760px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.header__heading-container {
  grid-area: header__heading-container;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  padding-left: 30px;
}

.header__logo {
  display: block;
  margin: 0 1.3rem 0 0;
}

.header__heading {
  text-align: left;
  padding: 0;
  margin: 0;
  color: #003fcf;
  line-height: 1;
  font-size: 2.5rem;
  font-weight: 800;
}

.header__heading-status {
  font-size: 1.25rem;
  color: #353b42;
  font-weight: 400;
  display: block;
}

.header__subheading {
  left: 10px;
  position: absolute;
  transform: rotate(-90deg);
  margin: 0;
  transform-origin: 0% 50%;
  display: block;
  font-size: 0.75rem;
  font-weight: 400;
  color: #353b42;
}

.header__home-link {
  color: inherit;
  text-decoration-color: none;
}

.header__additional {
  grid-area: header__additional;
  gap: 1rem;
  width: 100%;
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: opacity .5s ease-in;
}

.header__nav--toggled + .header__additional {
  margin-top: 1rem;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
}

@media screen and (min-width: 600px) {
  .header__additional {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    width: auto;
    height: auto;
    margin-top: 0;
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 900px) {
  .header__additional {
    margin-bottom: 0;
  }
}

.header__social {
  background-color: #003fcf;
  box-shadow: -10px 0 25px rgba(41, 42, 45, 0.75);
  width: 100%;
}

.header__social .list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;
  margin-bottom: 0;
  padding: 1rem 1.85rem;
  width: 100%;
}

.header__social .list__item {
  display: inline-flex;
  margin-bottom: 0;
}

.header__social .list__link {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 700;
  gap: 0.25rem;
  max-width: 5em;
  text-align: center;
  line-height: 1.24;
}

@media screen and (min-width: 900px) {
  .header__social .list {
    padding: 2rem 1rem;
    flex-direction: column;
  }
}

.header__social object {
  pointer-events: none;
}

.header__social a,
.header__social a:link,
.header__social a:visited {
  color: #ffffff;
  text-decoration: none;
}

.header__social a svg,
.header__social a:link svg,
.header__social a:visited svg {
  fill: #ffffff;
}

.header__social a:hover,
.header__social a:focus,
.header__social a:active {
  text-decoration: underline;
}

@media screen and (min-width: 600px) {
  .header__social {
    width: auto;
  }
  .header__social .list {
    width: auto;
  }
}

.header__nav {
  grid-area: main-nav;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

@media screen and (max-width: 599px) {
  .header__nav {
    order: 4;
  }
}

.header__nav-button {
  position: absolute;
  right: 1rem;
  top: .5rem;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 600px) {
  .header__nav-button {
    display: none;
  }
}

.header__nav-button, .header__nav-button:link, .header__nav-button:visited {
  text-decoration: none;
}

.header__nav-button > .hamburger {
  padding: 8px 7px;
  border-radius: 5px;
}

.header__nav-button .nav-button__title {
  font-size: 0.9375rem;
  margin: 0;
}

@media screen and (min-width: 600px) {
  .header__content {
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    justify-content: space-between;
    grid-template-areas: "header__heading-container . header__additional" "main-nav main-nav main-nav";
  }
}

@media screen and (min-width: 900px) {
  .header__content {
    padding-right: 110px;
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    justify-content: space-between;
    grid-template-areas: "header__heading-container . header__additional" "main-nav main-nav main-nav";
  }
  .header__social {
    position: absolute;
    right: 0;
    top: -1rem;
  }
}

@media screen and (min-width: 1200px) {
  .header__content {
    grid-template-areas: "header__heading-container main-nav header__additional";
  }
}

.additional {
  position: relative;
  padding: 0;
  margin-top: 1rem;
}

@media screen and (min-width: 600px) {
  .additional::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 1920px;
    width: 100%;
    padding-bottom: 116px;
    background: transparent url(/images/local/v-202503271106/home-bottom-prechod.png) no-repeat center bottom;
    z-index: 25;
  }
}

.additional .centered {
  padding-top: 3rem;
  padding-bottom: 2rem;
  background: #003fcf;
  color: #ffffff;
}

@media screen and (min-width: 750px) {
  .additional {
    margin-top: 15%;
  }
  .additional .centered {
    padding-top: 4rem;
    padding-bottom: 6.5rem;
    background: linear-gradient(90deg, #0061cf 40%, #003fcf 60%);
  }
}

.additional a,
.additional a:link,
.additional a:visited {
  color: #ffffff;
  text-decoration: underline;
}

.additional a:hover,
.additional a:focus,
.additional a:active {
  text-decoration: none;
}

.additional__content {
  display: grid;
  grid-gap: 2rem;
  gap: 2rem;
}

@media screen and (min-width: 750px) {
  .additional__content {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3%;
    gap: 3%;
  }
}

@media screen and (min-width: 1024px) {
  .additional__content {
    grid-template-columns: .75fr .75fr 1.5fr;
  }
}

.additional .element-type-heading {
  font-size: 1.6875rem;
  font-family: "Nunito", sans-serif;
  color: #ffffff;
  font-weight: 800;
  margin-bottom: .75em;
}

.additional .text-component {
  margin-bottom: 0;
}

.office-hours {
  color: #ffffff;
}

.office-hours .table-wrapper {
  margin: 0;
}

.office-hours table {
  margin: 0;
}

.office-hours tbody tr {
  border-bottom: 0;
}

.office-hours tbody tr:nth-child(odd) {
  background-color: transparent;
}

.office-hours tbody td {
  padding: 0;
  min-width: 0;
}

.page-footer,
.homepage-footer {
  text-align: center;
  background-color: #eaedf1;
  color: #292A2D;
  padding: 3.5em 0 2em;
}

.page-footer .centered,
.homepage-footer .centered {
  display: flex;
  flex-direction: column-reverse;
}

.page-footer .footer__heading,
.homepage-footer .footer__heading {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  flex-grow: 1;
}

.page-footer .footer__heading .element-type-heading,
.homepage-footer .footer__heading .element-type-heading {
  font-size: 1.125rem;
  font-weight: 400;
}

.page-footer .footer__heading .element-type-heading b,
.homepage-footer .footer__heading .element-type-heading b {
  font-weight: 800;
}

.page-footer .footer__bottom,
.homepage-footer .footer__bottom {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 2px solid #d0d8e3;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
}

.page-footer .page-footer__list,
.homepage-footer .page-footer__list {
  display: flex;
  flex-wrap: wrap;
  gap: 0 2rem;
}

.page-footer .page-footer__item:not(:last-child):after,
.homepage-footer .page-footer__item:not(:last-child):after {
  content: normal;
}

.page-footer a,
.page-footer a:link,
.page-footer a:visited,
.homepage-footer a,
.homepage-footer a:link,
.homepage-footer a:visited {
  color: #292A2D;
}

.article__heading {
  font-size: 2.125rem;
  line-height: 1.15;
  margin: 0 0 .75em;
  font-weight: 800;
}

.-nosidebar .article__heading {
  text-align: center;
}

.article__approved {
  padding: 1em 0 0;
}

.article__footer {
  margin: 1.5em 0 2em;
}

.article__footer div {
  display: inline-block;
}

.article__footer div input[type="date"],
.article__footer div input[type="time"] {
  display: inline;
  border: 0;
  padding: 0 .5em;
  border-radius: 3px;
}

.article__footer .invalid ul {
  margin: 0;
  padding: 0 0 0 .5em;
}

.article__perex {
  font-size: 1.1em;
  margin-bottom: 1.5em;
}

.article__image {
  margin-left: auto;
  margin-right: auto;
}

.article__body {
  margin-bottom: 0em;
}

.article__author {
  text-align: left;
  clear: both;
  margin: 1.5em 0;
  display: inline;
  font-style: normal;
}

.article__datespan {
  margin-bottom: 3em;
}

.article__date {
  font-weight: bold;
}

.article-link__image {
  display: block;
  max-width: 240px;
  margin: 0 0 1rem;
  flex-shrink: 0;
}

.article__link-elements .article-link__image {
  max-width: 100px;
}

@media screen and (min-width: 500px) {
  .article-link__image {
    display: inline-block;
    float: left;
    margin: 0 1rem 0 0;
  }
}

.article-link__title {
  font-size: 1.125rem;
}

.article-link__description {
  font-size: 1rem;
  margin-top: 0;
}

.article-link,
.article-link:link {
  color: #162847;
  text-decoration: underline;
}

.article-link:visited {
  color: #0a1220;
}

.article-link:hover,
.article-link:focus,
.article-link:active {
  text-decoration: none;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0 0 1rem;
}

.list .list {
  padding: 0 0 0 2em;
}

.list__item {
  margin: 0 0 .7em;
}

.list__description {
  margin-top: .25em;
}

.news .list,
.board .list,
.ytb-videos .list {
  display: grid;
  margin: 0 0 2.7rem;
}

@media screen and (min-width: 500px) and (max-width: 1024px) {
  .news .list,
  .board .list,
  .ytb-videos .list {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2%;
    gap: 2%;
  }
}

@media screen and (min-width: 1023px) {
  .news .list,
  .board .list,
  .ytb-videos .list {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2%;
    gap: 2%;
  }
}

.ytb-videos .list iframe {
  border: 0;
}

.org-contacts__contact span:not(:first-of-type)::before {
  background-color: #003fcf;
}

/*
* DESIGN COMPONENTS
*/
.button {
  border-radius: 4px;
  padding: 5px 2em;
  margin: 0;
  min-height: 42px;
  background-color: #162847;
  color: #ffffff;
  text-align: center;
  transition: background-color .15s ease;
  position: relative;
  cursor: pointer;
}

.button:not([disabled]):hover, .button:not([disabled]):focus, .button:not([disabled]):active {
  z-index: 3;
  outline: 0;
}

.button[disabled] {
  color: #999999;
  background-color: #ebebeb;
  cursor: not-allowed;
}

.button.-icon::before {
  vertical-align: middle;
  margin-right: .75em;
  display: inline-block;
}

.button.-block {
  display: block;
  width: 100%;
}

.button.-inverted {
  background-color: #162847;
  color: #ffffff;
}

.button.-primary {
  background-color: #003fcf;
  color: #ffffff;
}

.button.-primary.-inverted {
  background-color: #ffffff;
  color: #003fcf;
}

.button.-primary:not([disabled]):hover, .button.-primary:not([disabled]):focus, .button.-primary:not([disabled]):active {
  background-color: #162847;
  color: #ffffff;
}

.input {
  min-height: 42px;
  padding: .3em 1em;
}

.input.-inline {
  display: inline-block;
  width: auto;
  margin-right: .25em;
}

.input.-main {
  min-height: 54px;
  font-size: 1.15em;
}

.textarea {
  padding: .5em 1em;
  resize: vertical;
  height: auto;
  min-height: 10em;
}

@use "sass:math";
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
/* MARKUP */
/*
<button class="hamburger" type="button">
  <span class="hamburger-box">
    <span class="hamburger-inner"></span>
  </span>
</button>
 */
.hamburger {
  padding: 0 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger.is-active:hover {
  opacity: 0.7;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #003fcf;
}

.hamburger-box {
  width: 22px;
  height: 19px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 22px;
  height: 3px;
  background-color: #003fcf;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -8px;
}

.hamburger-inner::after {
  bottom: -8px;
}

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse .hamburger-inner::after {
  top: -16px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}

.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.universal-popup-component {
  position: fixed;
  overflow: hidden;
  z-index: 90;
  left: -9999rem;
  width: 0;
  height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  color: #323232;
}

.universal-popup-component::before {
  position: fixed;
  z-index: 1;
  opacity: 0;
  transition: opacity .15s ease-out;
}

.universal-popup-component__form {
  position: relative;
  z-index: 2;
  transform: scale(0);
  background-color: #ffffff;
  border-radius: 15px;
  transition: transform .15s ease-out, box-shadow .2s ease-out;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  max-width: 80vw;
  max-height: 70vh;
  min-height: 10em;
  width: 36em;
  padding: 3vmax;
}

.universal-popup-component__heading {
  font-family: inherit;
  color: inherit;
  margin: 0 0 .66em;
  padding: 0;
  text-transform: none;
  font-size: 2.1875rem;
}

.universal-popup-component__row {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.universal-popup-component__row:empty {
  display: none;
}

.universal-popup-component__row:not(:last-child) {
  margin-bottom: 20px;
}

.universal-popup-component__row.-end {
  align-items: flex-end;
}

.universal-popup-component__row.-center {
  align-items: center;
}

.universal-popup-component__label {
  font-family: inherit;
  font-size: inherit;
  margin: 0 0 6px;
}

.universal-popup-component__input {
  font-family: inherit;
  font-size: inherit;
  height: 54px;
  border-radius: 6px;
  border: 1px #cccccc solid;
  outline: 0;
}

.universal-popup-component__button {
  font-family: inherit;
  font-size: inherit;
  border-radius: 5px;
  background-color: #888888;
  color: #ffffff;
}

.universal-popup-component__close {
  font-size: 1.25rem;
  font-weight: 600;
  position: absolute;
  right: 1em;
  top: 1em;
  line-height: 0;
  width: 20px;
  height: 20px;
  text-decoration: none;
}

.universal-popup-component.-visible, .universal-popup-component:target {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.universal-popup-component.-visible::before, .universal-popup-component:target::before {
  content: "";
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  background: rgba(0, 0, 0, 0.7);
}

@keyframes popup-bounce {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.universal-popup-component.-visible .universal-popup-component__form, .universal-popup-component:target .universal-popup-component__form {
  z-index: 2;
  animation: popup-bounce .3s ease-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  transform-origin: 50% 100%;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
}

.breadcrumbs {
  text-align: right;
  color: #292A2D;
  padding-top: 1em;
  padding-bottom: 1em;
}

@media screen and (min-width: 800px) {
  .breadcrumbs {
    padding-top: 2.2em;
    padding-bottom: 1.7em;
  }
}

.breadcrumbs .centered.-padded {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

.breadcrumb-nav {
  margin-top: 0;
  margin-bottom: 0;
}

.-nosidebar .breadcrumb-nav {
  text-align: center;
}

.breadcrumb-nav__item {
  display: inline;
}

.breadcrumb-nav__item:not(.-active)::after {
  color: #9a9a9a;
  content: " \>";
  padding: 0 0.5rem;
}

.breadcrumb-nav__link {
  font-size: 0.8125rem;
  font-weight: 700;
  text-decoration: underline;
  display: inline-block;
}

.breadcrumb-nav .breadcrumb-nav__link,
.breadcrumb-nav .breadcrumb-nav__link:link,
.breadcrumb-nav .breadcrumb-nav__link:visited {
  color: #162847;
  text-decoration: underline;
}

.breadcrumb-nav .breadcrumb-nav__link.-active,
.breadcrumb-nav .breadcrumb-nav__link.-active:link,
.breadcrumb-nav .breadcrumb-nav__link.-active:visited {
  color: #162847;
  pointer-events: none;
  font-weight: 700;
  text-decoration: none;
}

.breadcrumb-nav .breadcrumb-nav__link:hover,
.breadcrumb-nav .breadcrumb-nav__link:focus,
.breadcrumb-nav .breadcrumb-nav__link:active {
  text-decoration: none;
}

.article .main-search__aside {
  background-color: transparent;
}

.form {
  padding: 2em 0;
  margin: 0 0 2em 0;
}

.form__fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.form__fields > :not(.form__section) {
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.form__section {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  flex-basis: 50%;
  padding: 0 1.25rem;
  min-width: 18em;
  margin-bottom: 1.1em;
}

.form__section.-inline {
  display: inline-block;
  padding: 0;
}

.form__section.-full {
  width: 100%;
  flex-basis: 100%;
}

.form__section.-to-end {
  justify-content: flex-end;
}

.form__section.-to-center, .form__section.-to-right {
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: 1;
  width: 100%;
  margin-top: 1em;
}

.form__section.-to-center {
  align-items: flex-start;
  justify-content: center;
  text-align: center;
}

.form__section.-to-right {
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
}

.form__section.-to-left {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}

.form__section .label {
  margin-bottom: .6em;
}

.form__section .label.-inline {
  display: inline-block;
  margin-right: .25em;
}

.form__section .button:nth-last-child(n+2), .form__section .button:nth-last-child(n+2) ~ .button {
  margin-bottom: .5em;
}

.form__section .button:not(:first-child):not(.-block) {
  margin-left: .5em;
}

.form__label {
  display: block;
  margin-bottom: .4em;
  cursor: default;
}

.form__file-value {
  color: inherit;
  opacity: .8;
}

.form__fieldset {
  padding: .85em 1.5em .625em;
  border: 1px #cccccc solid;
  border-radius: 4px;
  background-color: #fff;
}

.form__legend {
  padding: 0 .5em;
  font-size: 1.35rem;
  font-weight: bold;
}

.inline-form.form {
  padding-bottom: 0;
}

.inline-form__fields.-bordered-top {
  border-top: 2px solid #c3c3c3;
  padding-top: 1.5em;
}

.inline-form__fields.-bordered-bottom {
  border-bottom: 2px solid #c3c3c3;
  padding-bottom: .5em;
}

.contact-form {
  padding: 0;
  margin-bottom: 0;
}

.contact-form .form__section {
  margin-bottom: .5em;
}

.contact-form__input, .contact-form__textarea {
  background-color: #ffffff;
  border-color: #292A2D;
  color: #292A2D;
  border-radius: 1px;
}

.contact-form__input {
  min-height: 55px;
}

.contact-form__info:first-child {
  margin: .55em 0 .9em;
}

.contact__line {
  padding-left: 50px;
  margin: 0 0 1em;
}

.contact__line.-location, .contact__line.-phone, .contact__line.-email {
  padding-top: .15em;
  padding-bottom: .15em;
  background-repeat: no-repeat;
  background-position: 0 50%;
}

.contact__line.-location {
  background-image: url("data:image/svg+xml;utf8,%3Csvg width='30' height='30' viewBox='0 0 30 30' version='1.1' xmlns='http://www.w3.org/2000/svg' xml:space='preserve'%3E%3Cpath d='M15,0.405C20.9,0.405 25.683,5.188 25.683,11.088C25.683,16.989 16.532,29.595 14.879,29.595C13.226,29.595 4.317,16.989 4.317,11.088C4.317,5.188 9.1,0.405 15,0.405ZM14.989,5.854C17.886,5.854 20.234,8.202 20.234,11.1C20.234,13.996 17.886,16.344 14.989,16.344C12.092,16.344 9.744,13.996 9.744,11.1C9.744,8.202 12.092,5.854 14.989,5.854Z' fill='%23ffffff'/%3E%3C/svg%3E");
}

.contact__line.-phone {
  background-image: url("data:image/svg+xml;utf8,%3Csvg width='30' height='30' viewBox='0 0 30 30' version='1.1' xmlns='http://www.w3.org/2000/svg' xml:space='preserve'%3E%3Cpath d='M221.594,1599.46L204.711,1599.46C204.014,1599.46 203.449,1598.9 203.449,1598.2L203.449,1570.12C203.449,1569.42 204.014,1568.85 204.711,1568.85L221.594,1568.85C222.291,1568.85 222.856,1569.42 222.856,1570.12L222.856,1598.2C222.856,1598.9 222.295,1599.46 221.594,1599.46ZM213.124,1594.92C213.913,1594.92 214.552,1595.56 214.552,1596.35C214.552,1597.14 213.913,1597.78 213.124,1597.78C212.336,1597.78 211.697,1597.14 211.697,1596.35C211.697,1595.56 212.336,1594.92 213.124,1594.92ZM219.169,1571.7L207.134,1571.7C206.498,1571.7 205.988,1572.22 205.988,1572.85L205.988,1591.76C205.988,1592.39 206.501,1592.9 207.134,1592.9L219.169,1592.9C219.803,1592.9 220.315,1592.39 220.315,1591.76L220.315,1572.85C220.315,1572.22 219.805,1571.7 219.169,1571.7ZM23.166,29.805L6.834,29.805C6.16,29.805 5.613,29.259 5.613,28.584L5.613,1.416C5.613,0.742 6.16,0.195 6.834,0.195L23.166,0.195C23.84,0.195 24.387,0.742 24.387,1.416L24.387,28.584C24.387,29.257 23.843,29.802 23.166,29.805ZM14.973,25.412C15.735,25.412 16.354,26.03 16.354,26.793C16.354,27.556 15.735,28.175 14.973,28.175C14.21,28.175 13.592,27.556 13.592,26.793C13.592,26.03 14.21,25.412 14.973,25.412ZM20.82,2.949L9.178,2.949C8.563,2.951 8.069,3.447 8.069,4.057L8.069,22.352C8.069,22.964 8.566,23.461 9.178,23.461L20.82,23.461C21.432,23.461 21.928,22.964 21.928,22.352L21.928,4.057C21.928,3.447 21.435,2.951 20.82,2.949Z' fill='%23ffffff'/%3E%3C/svg%3E");
}

.contact__line.-email {
  background-image: url("data:image/svg+xml;utf8,%3Csvg width='30' height='30' viewBox='0 0 30 30' version='1.1' xmlns='http://www.w3.org/2000/svg' xml:space='preserve'%3E%3Cpath d='M26.198,22.835L3.802,22.835C3.622,22.835 3.449,22.806 3.287,22.753L10.652,15.388L14.704,17.482L14.856,17.778L15.068,17.669L15.151,17.713L15.212,17.596L19.389,15.455L26.694,22.759C26.538,22.808 26.371,22.835 26.198,22.835ZM20.94,14.66L27.857,11.116L27.857,21.176C27.857,21.3 27.843,21.421 27.817,21.537L20.94,14.66ZM9.105,14.589L2.178,21.516C2.155,21.407 2.143,21.293 2.143,21.176L2.143,10.992L9.105,14.589ZM15.07,15.804L2.143,9.125L2.143,8.824C2.143,7.908 2.886,7.165 3.802,7.165L26.198,7.165C27.114,7.165 27.857,7.908 27.857,8.824L27.857,9.253L15.07,15.804Z' fill='%23ffffff'/%3E%3C/svg%3E");
}

.search {
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  flex-grow: 0;
  padding: 1em 0 1em;
  margin-left: auto;
}

@media screen and (min-width: 600px) {
  .search {
    padding: 1rem 0 1rem 1rem;
  }
  .search__form {
    flex-grow: 0;
  }
  .search__input {
    width: 13em;
  }
}

/*******************************
* FULLSCREEN-SEARCH
*******************************/
.fullscreen-search-anchor {
  display: flex;
  z-index: 5;
  white-space: nowrap;
  font-family: "Nunito", sans-serif;
}

.fullscreen-search-anchor__text {
  padding-right: .5em;
}

.fullscreen-search-anchor svg {
  display: inline-block;
  width: 23px;
  height: 24px;
}

.fullscreen-search-anchor, .fullscreen-search-anchor:link, .fullscreen-search-anchor:visited {
  text-decoration: underline;
}

.fullscreen-search-anchor:hover, .fullscreen-search-anchor:focus, .fullscreen-search-anchor:active {
  text-decoration: none;
}

@media screen and (min-width: 1024px) {
  .fullscreen-search-anchor {
    position: static;
  }
}

.fullscreen-search {
  transition: background-color .1s ease-out;
  position: fixed;
  top: 0;
  left: -999em;
  z-index: 19000;
  width: .1px;
  height: .1px;
  overflow: hidden;
  text-align: center;
  font-family: "Nunito", sans-serif;
  background: rgba(251, 216, 52, 0.9);
}

.fullscreen-search:target, .fullscreen-search[data-is-shown="true"] {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: auto;
  height: auto;
  backdrop-filter: blur(8px) grayscale(100%);
}

.fullscreen-search form {
  display: block;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  width: 80%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 10% 0 0 0;
  text-align: left;
  color: #292A2D;
  line-height: 1.4;
}

@media screen and (min-height: 35em) {
  .fullscreen-search form {
    padding-top: 14%;
  }
}

@media screen and (orientation: portrait) {
  .fullscreen-search form {
    padding-top: 33%;
  }
}

.fullscreen-search__close {
  transition: background-color .15s ease-out;
  position: absolute;
  top: 0;
  right: 0;
  width: 72px;
  height: 72px;
  cursor: pointer;
  background: transparent url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='%23292A2D' d='M21.422 20l15.287 15.287c.393.393.393 1.029 0 1.422s-1.029.393-1.422 0l-15.287-15.287-15.287 15.287c-.393.393-1.029.393-1.422 0s-.393-1.029 0-1.422l15.287-15.287-15.287-15.287c-.393-.393-.393-1.029 0-1.422s1.029-.393 1.422 0l15.287 15.287 15.287-15.287c.393-.393 1.029-.393 1.422 0s.393 1.029 0 1.422l-15.287 15.287z'/%3E%3C/svg%3E") no-repeat 50% 50%;
}

.fullscreen-search .fullscreen-search__close:hover,
.fullscreen-search .fullscreen-search__close:focus,
.fullscreen-search .fullscreen-search__close:active {
  background-color: #292A2D;
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='%23ffffff' d='M21.422 20l15.287 15.287c.393.393.393 1.029 0 1.422s-1.029.393-1.422 0l-15.287-15.287-15.287 15.287c-.393.393-1.029.393-1.422 0s-.393-1.029 0-1.422l15.287-15.287-15.287-15.287c-.393-.393-.393-1.029 0-1.422s1.029-.393 1.422 0l15.287 15.287 15.287-15.287c.393-.393 1.029-.393 1.422 0s.393 1.029 0 1.422l-15.287 15.287z'/%3E%3C/svg%3E");
}

.fullscreen-search__label {
  font-size: 1.125rem;
  display: block;
  margin: 0 0 .9em 0;
  color: #292A2D;
  text-transform: uppercase;
  font-weight: 600;
}

.fullscreen-search__container {
  position: relative;
  width: 100%;
}

.fullscreen-search__button {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent none;
  box-shadow: none;
  appearance: none;
  border: 0;
  background: transparent;
  color: #292A2D;
  width: 2em;
  height: 2em;
  position: absolute;
  right: 0;
  top: 50%;
  margin: -1.2em 0 0 0;
  padding: 0;
}

.fullscreen-search__button:focus {
  box-shadow: none;
}

@media screen and (min-width: 1100px) {
  .fullscreen-search__button {
    width: 3.8em;
    height: 3.8em;
    margin: -2.2em 0 0 0;
  }
}

@media screen and (min-width: 1490px) {
  .fullscreen-search__button {
    width: 5em;
    height: 5em;
    margin: -2.5em 0 0 0;
  }
}

.fullscreen-search__input {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent none;
  box-shadow: none;
  box-sizing: border-box;
  font-size: 1.5rem;
  width: 100% !important;
  height: auto !important;
  font-family: "Nunito", sans-serif;
  background: transparent !important;
  color: #292A2D;
  outline: 0;
  font-weight: normal;
  padding: 0 1em 0 0 !important;
  border: dotted rgba(41, 42, 45, 0.8) !important;
  border-width: 0 0 2px !important;
  text-indent: 0 !important;
}

.fullscreen-search__input:focus {
  box-shadow: none !important;
}

.fullscreen-search__input::placeholder {
  color: rgba(41, 42, 45, 0.8);
}

@media screen and (min-width: 600px) {
  .fullscreen-search__input {
    font-size: 4vw;
  }
}

@media screen and (min-width: 1490px) {
  .fullscreen-search__input {
    font-size: 5rem;
  }
}

#google_translate_element {
  display: inline-block;
  margin: 0;
  align-self: center;
  flex-shrink: 0;
}

#google_translate_element.field {
  background-color: transparent;
}

#google_translate_element .goog-te-gadget-simple {
  border: 0;
  width: 100%;
  padding-bottom: 1px;
  background-color: transparent;
  display: flex;
  align-items: center;
}

#google_translate_element .goog-te-gadget-simple,
#google_translate_element .goog-te-gadget-simple * {
  font-family: "Nunito", sans-serif;
  font-size: 1rem !important;
}

#google_translate_element .goog-te-gadget-simple .goog-te-gadget-icon {
  width: 24px;
  height: 24px;
  padding: 0;
  order: 2;
  margin: 0;
  background-repeat: no-repeat;
}

#google_translate_element .goog-te-gadget-simple [aria-haspopup] {
  margin-right: 0;
  display: none;
  text-decoration: none !important;
}

#google_translate_element .goog-te-gadget-simple [aria-haspopup] span:nth-of-type(1) {
  padding: 0 3px;
  display: inline-block;
  color: #292A2D;
  text-decoration: underline;
}

#google_translate_element .goog-te-gadget-simple [aria-haspopup] span:nth-of-type(1):hover, #google_translate_element .goog-te-gadget-simple [aria-haspopup] span:nth-of-type(1):focus, #google_translate_element .goog-te-gadget-simple [aria-haspopup] span:nth-of-type(1):active {
  text-decoration: none;
}

#google_translate_element .goog-te-gadget-simple [aria-haspopup] span:nth-of-type(2) {
  padding-right: 3px;
  border-left: 0 !important;
}

#google_translate_element .goog-te-gadget-simple [aria-haspopup] span:nth-of-type(3) {
  display: none;
  background: none;
  color: #292A2D !important;
}

#google_translate_element .goog-te-gadget-simple [aria-haspopup] img {
  display: none;
}

@media screen and (max-width: 599px) {
  #google_translate_element,
  #goog-gt-tt,
  #goog-gt-tt + div > object,
  iframe.goog-te-menu-frame,
  iframe.goog-te-banner-frame,
  .header__social .list__item.-gte {
    display: none !important;
  }
}

.main-nav {
  color: #292A2D;
  padding: 0 0 0;
}

.main-nav .nav.-root-nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 1rem 0 0;
  padding: 0;
}

@media screen and (min-width: 600px) {
  .main-nav .nav.-root-nav {
    margin: 0;
    flex-direction: row;
  }
}

.main-nav .nav__item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 1.5rem 0;
  font-family: "Nunito", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
}

.main-nav .nav__link {
  position: relative;
  display: block;
  padding: .75em 0;
  line-height: 1;
  text-transform: uppercase;
}

.main-nav .nav__link, .main-nav .nav__link:link, .main-nav .nav__link:visited {
  color: #292A2D;
  text-decoration: none;
}

.main-nav .nav__link:hover, .main-nav .nav__link:focus, .main-nav .nav__link:active {
  color: #003fcf;
}

.main-nav .nav__link.-active, .main-nav .nav__link.-active-parent {
  text-decoration: none;
}

.main-nav .nav__link.-active, .main-nav .nav__link.-active:link, .main-nav .nav__link.-active:visited, .main-nav .nav__link.-active-parent, .main-nav .nav__link.-active-parent:link, .main-nav .nav__link.-active-parent:visited {
  color: #003fcf;
}

.main-nav .nav__description {
  display: none;
}

@media screen and (max-width: 599px) {
  .main-nav {
    transition: max-height 0.3s ease-out;
    max-height: 0;
    overflow: hidden;
    padding: 0;
  }
  .main-nav__item {
    animation: menuFadeOut 0.1s ease-out 0s forwards;
    opacity: 0;
  }
  .main-nav__item:nth-of-type(2) {
    animation-delay: 50ms !important;
  }
  .main-nav__item:nth-of-type(3) {
    animation-delay: 100ms !important;
  }
  .main-nav__item:nth-of-type(4) {
    animation-delay: 150ms !important;
  }
  .main-nav__item:nth-of-type(5) {
    animation-delay: 200ms !important;
  }
  .main-nav__item:nth-of-type(6) {
    animation-delay: 250ms !important;
  }
  .main-nav__item:nth-of-type(7) {
    animation-delay: 300ms !important;
  }
  .main-nav__item:nth-of-type(8) {
    animation-delay: 350ms !important;
  }
  .main-nav__item:nth-of-type(9) {
    animation-delay: 400ms !important;
  }
  .main-nav__item:nth-of-type(10) {
    animation-delay: 450ms !important;
  }
  .header__nav--toggled .main-nav {
    max-height: 20rem;
  }
  .header__nav--toggled .main-nav__item {
    animation: menuFadeIn 0.3s ease-out 0s forwards;
  }
}

.figure__caption {
  padding: .725em 10px 1.5em;
  border-bottom: 1px #cccccc solid;
}

.figure__caption.-photo, .figure__caption.-video, .figure__caption.-illustration {
  padding-left: 30px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 10px .72em;
  background-size: 20px auto;
}

@media screen and (min-width: 800px) {
  .figure__caption.-photo, .figure__caption.-video, .figure__caption.-illustration {
    padding-left: 50px;
    background-position: 10px .48em;
    background-size: auto;
  }
}

.figure__caption.-photo {
  background-image: icon("camera", #ccc);
}

.figure__caption.-video {
  background-image: icon("movie", #ccc);
}

.figure__caption.-illustration {
  background-image: icon("picture", #ccc);
}

.text-component {
  margin-bottom: 2em;
  overflow: hidden;
}

.text-component p:first-child {
  margin-top: 0;
}

.text-component p:last-child {
  margin-bottom: 0;
}

.text-component .image-align-left,
.text-component .image-align-right {
  margin: 0;
}

@media screen and (min-width: 500px) {
  .text-component .image-align-left img,
  .text-component .image-align-right img {
    max-width: 42.5%;
  }
}

.text-component .image-align-left img {
  float: left;
  margin: 0 1em .75em 0;
}

.text-component .image-align-left > *:first-of-type img {
  clear: both;
}

.text-component .image-align-right img {
  float: right;
  margin: 0 0 .75em 1em;
}

.text-component .image-align-right > *:first-of-type img {
  clear: both;
}

.text-component .image-align-center {
  text-align: center;
}

.text-component .image-align-center img {
  margin: 0 0 .75em;
  max-width: 100%;
}

.text-component [contenteditable="true"] .images-container:focus img, .text-component [contenteditable="true"] .images-container:hover img,
.text-component [contenteditable="true"] .image:focus img,
.text-component [contenteditable="true"] .image:hover img {
  box-shadow: 0 0 5px red;
}

.sidebar .nav {
  background-color: #0060cf;
  padding: 18% 11%;
}

.sidebar .nav__upper_node_link {
  position: relative;
  display: flex;
  font-size: 1.375rem;
  font-weight: 800;
  padding: 1rem 0 1rem 3.6rem;
  margin-bottom: 1.25rem;
}

.sidebar .nav__upper_node_link::before {
  position: absolute;
  top: 50%;
  left: 0;
  content: url(/images/local/icons/v-202503271106/arrow-right.png);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  background-color: #003fcf;
  border-radius: 50%;
  transform: rotate(180deg) translateY(50%);
  transition: background-color .3s ease-in-out;
}

.sidebar .nav__upper_node_link:hover::before, .sidebar .nav__upper_node_link:focus::before, .sidebar .nav__upper_node_link:active::before {
  background-color: #162847;
}

.sidebar .nav.nav.-root-nav {
  padding: 0 1rem 0 3.6rem;
  margin-top: 0;
  list-style-type: none;
}

.sidebar .nav.nav.-sub-nav {
  margin-top: .75em;
  margin-bottom: 0;
  padding-left: 24px;
}

.sidebar .nav__list {
  padding-left: 0em;
  list-style-type: none;
  margin: 2.2em 0 1.5em;
}

.sidebar .nav__item {
  font-size: 1.125rem;
  font-weight: 400;
  margin: 0 0 .3em;
}

.sidebar .nav__item .nav__item {
  font-size: 1rem;
  font-weight: 300;
  text-transform: none;
}

.sidebar .nav__item.-has-children > .nav__link {
  font-weight: 500;
}

.sidebar .nav__item.-expanded {
  background-color: rgba(0, 0, 0, 0.1);
}

.sidebar .nav__item.-current {
  background-color: rgba(0, 0, 0, 0.1);
}

.sidebar .nav__description {
  display: none;
}

.sidebar .nav__link {
  position: relative;
}

.sidebar .nav__link + .nav.-sub-nav .nav__link.-expandable .nav__toggle-button {
  left: -26px;
  top: .36em;
  width: 12px;
  height: 12px;
  background-image: url("data:image/svg+xml;utf8,%3Csvg width='12' height='12' viewBox='0 0 12 12' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5,5L5,1C5,0.448 5.448,0 6,0C6.552,0 7,0.448 7,1L7,5L11,5C11.552,5 12,5.448 12,6C12,6.552 11.552,7 11,7L7,7L7,11C7,11.552 6.552,12 6,12C5.448,12 5,11.552 5,11L5,7L1,7C0.448,7 0,6.552 0,6C0,5.448 0.448,5 1,5L5,5Z'/%3E%3C/svg%3E");
}

.sidebar .nav__link, .sidebar .nav__link:link, .sidebar .nav__link:visited {
  font-weight: 300;
  text-decoration: none;
}

.sidebar .nav__link:hover, .sidebar .nav__link:focus, .sidebar .nav__link:active {
  text-decoration: underline;
}

.sidebar .nav__link[aria-expanded=true] ~ .nav {
  display: block;
}

.sidebar .nav__link[aria-expanded=false] ~ .nav {
  display: none;
}

.sidebar .nav__link.-active, .sidebar .nav__link.-active-parent {
  text-decoration: none;
  font-weight: bold;
}

.sidebar .nav__toggle-button {
  font-size: 1rem;
  line-height: 0;
  position: absolute;
  transition: transform .2s ease-out;
  transform-origin: 50% 50%;
  left: -28px;
  top: .18em;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background: transparent url("data:image/svg+xml;utf8,%3Csvg width='16' height='16' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7,7L7,1C7,0.448 7.448,0 8,0C8.552,0 9,0.448 9,1L9,7L15,7C15.552,7 16,7.448 16,8C16,8.552 15.552,9 15,9L9,9L9,15C9,15.552 8.552,16 8,16C7.448,16 7,15.552 7,15L7,9L1,9C0.448,9 0,8.552 0,8C0,7.448 0.448,7 1,7L7,7Z'/%3E%3C/svg%3E") no-repeat 0 0;
}

.sidebar .nav[aria-expanded="true"] .nav__toggle-button {
  transform: rotate(225deg);
}

.sidebar .nav a,
.sidebar .nav a:link,
.sidebar .nav a:visited {
  color: #ffffff;
}
