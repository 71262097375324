body {
	font-family: $font-body;
}

@include headings(1, 6) {
	font-family: $font-headings;
}

@include headings(1, 4) {
	font-weight: 800;
	line-height: 1.15;
	margin: 0 0 .3em 0;

	/* * + & {
		margin-top: 2em;
	} */
}

@include headings(5, 6) {
	font-size: 700;
	margin: 0 0 .5em 0;

	*+& {
		margin-top: 1.65em;
	}
}

h2,
h3.-size-h2 {
	font-size: rem(28px);
}

h3 {
	font-size: rem(24px);
}

h4 {
	font-size: rem(22px);
}

h5 {
	font-size: rem(20px);
}

h6 {
	font-size: rem(18px);
}

@include link($visited: false) {
	color: $color-brand;
}

@include link-visited {
	color: lighten($color-brand, 10%);
}

@include link-over {
	text-decoration: none;
}
