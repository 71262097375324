[class*="lazy-"] {
	transition: filter .5s ease-out;
	filter: blur(20px);
}

[class*="lazy-"]:not(.loaded) {
	display: block;
	background: #dedede;
	width: 100%;
	min-width: 240px;
	padding-bottom: 56.25%;
}

[class*="lazy-"].loaded {
	filter: blur(0);
}

body {
	color: $color-base;
}

::selection {
	background-color: #1a86d0;
	color: #ffffff;
}


.centered {
	max-width: $max-width;
	width: 94%;
	margin-left: auto;
	margin-right: auto;

	&.-narrow {
		max-width: 920px;
	}

	&.-padded {
		width: 100%;
		padding-left: 4%;
		padding-right: 4%;
	}

	&.-padded-left {
		padding-left: 4%;
	}

	&.-padded-right {
		padding-right: 4%;
	}
}

//obsah na stránce
.main-wrapper{
	background: #e9f1f9;
	> .centered{
		display: grid;
		grid-gap: 0 70px;
		gap: 0 70px;
	}
	@include min(750px){
		margin-top: -50px;
		padding-top: 50px;
		background: #e9f1f9 url(/images/local/v-202503271106/page-bottom-bg.jpg) no-repeat center bottom;
	}
	@include min(800px) {
		&.-sidebar > .centered{
			grid-template-areas: "sidebar main";
			grid-template-columns: 444px 1fr;
			.main {
				grid-area: main;
			}
			.sidebar {
				position: relative;
				z-index: 20;
				grid-area: sidebar;
				margin-top: -5.6rem;
			}
		}
		.content{
			min-height: 750px;
			padding-bottom: 40%;
		}
	}
}

.table-wrapper {
	max-width: 100%;
	overflow-x: auto;
	margin-bottom: 2em;

	table {
		margin-bottom: 1em;
	}
}

.sidebar {
	padding-bottom: 2em;
}

.content {
	margin-left: auto;
	margin-right: auto;
}

.more {
	text-align: center;

	&__button {
		background: $color-tercialy;
		padding: 1em 2em;
		font-weight: 700;
		display: inline-block;
		font-size: rem(16px);
		min-width: 190px;
		border-radius: 5px;
		transition: background .3s ease-in-out, color .3s ease-in-out, box-shadow .3s ease-in-out;
	}
}

@include link(".more__button") {
	color: $color-brand;
	text-decoration: none;
}

@include link-over(".more__button") {
	background: linear-gradient(90deg, #0061cf 0%, #003fcf 100%);
	color: $color-light;
	box-shadow: 0px 35px 25px -25px rgba(#000000, .25);
}

