.page-footer,
.homepage-footer {
	text-align: center;
	background-color: #eaedf1;
	color: $color-dark;
	padding: 3.5em 0 2em;

	.centered{
		display: flex;
		flex-direction: column-reverse;
	}
	.footer{
		&__heading{
			@include flex(df, aic, fww);
			gap: 1.5rem;
			flex-grow: 1;
			.element-type-heading{
				font-size: rem(18px);
				font-weight: 400;
				b{
					font-weight: 800;
				}
			}
		}
		&__bottom{
			@include flex(df, jcsb, fww);
			border-bottom: 2px solid #d0d8e3;
			margin-bottom: 2rem;
			padding-bottom: 2rem;
		}
	}
	.page-footer__list{
		display: flex;
		flex-wrap: wrap;
		gap: 0 2rem;
	}
	.page-footer__item:not(:last-child):after{
		content: normal;
	}
	@include link {
		color: $color-dark;
	}
}
