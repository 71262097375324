
.article .main-search__aside{
	background-color: transparent;
}
.form {
	padding: 2em 0;
	margin: 0 0 2em 0;

	&__fields {
		@include flex(df, fww, jcsb);
		margin-left: -1.25rem;
		margin-right: -1.25rem;

		> :not(.form__section) {
			width: 100%;
			padding-left: 1.25rem;
			padding-right: 1.25rem;
		}
	}

	&__section {
		@include flex(df, fg1, fdc, jcfs);
		flex-basis: 50%;
		padding: 0 1.25rem;
		min-width: 18em;
        margin-bottom: 1.1em;
        &.-inline{
            display: inline-block;
            padding: 0;
        }

		&.-full {
			width: 100%;
			flex-basis: 100%;
		}

		&.-to-end {
			justify-content: flex-end;
		}

		&.-to-center,
		&.-to-right {
			@include flex(fdr, fww, fb1);
			width: 100%;
			margin-top: 1em;
		}

		&.-to-center {
			@include flex(aifs, jcc);
			text-align: center;
		}

		&.-to-right {
			@include flex(aife, jcfe);
			text-align: right;
		}

		&.-to-left {
			@include flex(aifs, jcfs);
			text-align: left;
		}

		.label {
            margin-bottom: .6em;
        }
        .label.-inline{
            display: inline-block;
            margin-right: .25em;
		}

		.button {
			@include quantity-query(min 2) {
				margin-bottom: .5em;
			}

			&:not(:first-child):not(.-block) {
				margin-left: .5em;
			}
		}
	}

	&__label {
		display: block;
		margin-bottom: .4em;
		cursor: default;
	}

	&__file-value {
		color: inherit;
		opacity: .8;
	}

	&__fieldset {
		padding: .85em 1.5em .625em;
		border: 1px $form-item-border-color solid;
		border-radius: $form-item-border-radius;
		background-color: #fff;
	}

	&__legend {
		padding: 0 .5em;
		font-size: 1.35rem;
		font-weight: bold;
	}
}

.inline-form{
    &.form{
        padding-bottom: 0;
    }
    &__fields{
        &.-bordered-top{
            border-top: 2px solid #c3c3c3;
            padding-top: 1.5em;
        }
        &.-bordered-bottom{
            border-bottom: 2px solid #c3c3c3;
            padding-bottom: .5em;
        }
    }
}
