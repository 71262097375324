.breadcrumbs {
	text-align: right;
	color: $color-dark;
	padding-top: 1em;
	padding-bottom: 1em;
	@include min(800px){
		padding-top: 2.2em;
		padding-bottom: 1.7em;
	}
	.centered.-padded{
		padding-left: 0;
		padding-right: 0;
		width: 100%;
	}
}

.breadcrumb-nav {
	margin-top: 0;
	margin-bottom: 0;
	.-nosidebar &{
		text-align: center;
	}

	&__item {
		display: inline;

		&:not(.-active)::after {
			color: #9a9a9a;
			content: " \>";
			padding: 0 0.5rem;
		}
	}

	&__link {
		font-size: rem(13px);
		font-weight: 700;
		text-decoration: underline;
		display: inline-block;
	}

	@include link(".breadcrumb-nav__link") {
		color: $color-brand;
		text-decoration: underline;
	}

	@include link(".breadcrumb-nav__link.-active") {
		color: $color-brand;
		pointer-events: none;
		font-weight: 700;
		text-decoration: none;
	}

	@include link-over(".breadcrumb-nav__link") {
		text-decoration: none;
	}
}
