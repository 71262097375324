.search {
	position: relative;
	z-index: 3;
	@include flex(df, aic, fg0);
	padding: 1em 0 1em;
	margin-left: auto;

	@include min(600px) {
		padding: 1rem 0 1rem 1rem;

		&__form {
			flex-grow: 0;
		}

		&__input {
			width: 13em;
		}
	}
}

/*******************************
* FULLSCREEN-SEARCH
*******************************/

.fullscreen-search-anchor {
	display: flex;
	z-index: 5;
	white-space: nowrap;
	font-family: $font-headings;
	&__container{
		//flex-shrink: 0;
	}

	&__text {
		padding-right: .5em;
	}

	svg {
		display: inline-block;
		width: 23px;
		height: 24px;
	}

	@include link(&) {
		text-decoration: underline;
	}

	@include link-over(&) {
		text-decoration: none;
	}
}

@include min(1024px) {
	.fullscreen-search-anchor {
		position: static;
	}
}

.fullscreen-search {
	transition: background-color .1s ease-out;
	position: fixed;
	top: 0;
	left: -999em;
	z-index: 19000;
	width: .1px;
	height: .1px;
	overflow: hidden;
	text-align: center;
	font-family: $font-headings;
	background: rgba($color-tercialy, .9);

	&:target,
	&[data-is-shown="true"] {
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		width: auto;
		height: auto;
		backdrop-filter: blur(8px) grayscale(100%);
	}

	form {
		display: block;
		margin: 0;
		padding: 0;
		font-size: rem(16px);
		width: 80%;
		max-width: 1300px;
		margin: 0 auto;
		padding: 10% 0 0 0;
		text-align: left;
		color: $color-dark;
		line-height: 1.4;

		@media screen and (min-height: 35em) {
			padding-top: 14%;
		}

		@media screen and (orientation: portrait) {
			padding-top: 33%;
		}
	}

	&__close {
		transition: background-color .15s ease-out;
		position: absolute;
		top: 0;
		right: 0;
		width: 72px;
		height: 72px;
		cursor: pointer;
		background: transparent encode-svg('<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><path fill-rule="evenodd" clip-rule="evenodd" fill="#{$color-dark}" d="M21.422 20l15.287 15.287c.393.393.393 1.029 0 1.422s-1.029.393-1.422 0l-15.287-15.287-15.287 15.287c-.393.393-1.029.393-1.422 0s-.393-1.029 0-1.422l15.287-15.287-15.287-15.287c-.393-.393-.393-1.029 0-1.422s1.029-.393 1.422 0l15.287 15.287 15.287-15.287c.393-.393 1.029-.393 1.422 0s.393 1.029 0 1.422l-15.287 15.287z"/></svg>') no-repeat 50% 50%;
	}

	@include link-over(".fullscreen-search__close") {
		background-color: $color-dark;
		background-image: encode-svg('<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><path fill-rule="evenodd" clip-rule="evenodd" fill="#{$color-light}" d="M21.422 20l15.287 15.287c.393.393.393 1.029 0 1.422s-1.029.393-1.422 0l-15.287-15.287-15.287 15.287c-.393.393-1.029.393-1.422 0s-.393-1.029 0-1.422l15.287-15.287-15.287-15.287c-.393-.393-.393-1.029 0-1.422s1.029-.393 1.422 0l15.287 15.287 15.287-15.287c.393-.393 1.029-.393 1.422 0s.393 1.029 0 1.422l-15.287 15.287z"/></svg>')
	}

	&__label {
		font-size: rem(18px);
		display: block;
		margin: 0 0 .9em 0;
		color: $color-dark;
		text-transform: uppercase;
		font-weight: 600;
	}

	&__container {
		position: relative;
		width: 100%;
	}

	&__button {
		border-radius: 0;
		-webkit-appearance: none;
		-moz-appearance: none;
		background: transparent none;
		box-shadow: none;

		&:focus {
			box-shadow: none;
		}

		appearance: none;
		border: 0;
		background: transparent;
		color: $color-dark;
		width: 2em;
		height: 2em;
		position: absolute;
		right: 0;
		top: 50%;
		margin: -1.2em 0 0 0;
		padding: 0;

		@include min(1100px) {
			width: 3.8em;
			height: 3.8em;
			margin: -2.2em 0 0 0;
		}

		@include min($max-width) {
			width: 5em;
			height: 5em;
			margin: -2.5em 0 0 0;
		}
	}

	&__input {
		border-radius: 0;
		-webkit-appearance: none;
		-moz-appearance: none;
		background: transparent none;
		box-shadow: none;
		box-sizing: border-box;
		font-size: rem(24px);
		width: 100% !important;
		height: auto !important;
		font-family: $font-headings;
		background: transparent !important;
		color: $color-dark;
		outline: 0;
		font-weight: normal;
		padding: 0 1em 0 0!important;
		border: dotted rgba($color-dark, 0.8) !important;
		border-width: 0 0 2px !important;
		text-indent: 0 !important;
		&:focus {
			box-shadow: none !important;
		}

		&::placeholder {
			color: rgba($color-dark, .8);
		}

		@include min(600px) {
			font-size: 4vw;
		}

		@include min($max-width) {
			font-size: rem(80px);
		}
	}
}
